import { useMutation, useQueryClient } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { type FormEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { UserApi } from "../../api/loginApi";
import { Button, Link, TextBox, useErrorToast } from "../../components/basic";
import { UserContext } from "../../contexts/userContext";
import { RouteValues, Severity, encodeBase64, logger, setPayloadCookie } from "../../helpers";

interface State {
    email: string;
    password: string;
}

export default function Login() {
    const [state, setState] = useState<State>({
        email: "",
        password: "",
    });
    const { setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const errorToast = useErrorToast();

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        loginMutation.mutate(state);
    };

    const loginMutation = useMutation({
        mutationFn: UserApi.login,
        onSuccess: async (data) => {
            await queryClient.invalidateQueries();
            if (data.errorMessage)
                errorToast("Login Error", data.errorMessage);
            else if (setUser) {
                setUser(data);
                setPayloadCookie(encodeBase64(JSON.stringify(data)));
                navigate(`/${RouteValues.HOME}`);
            }
        },
        onError: (error: AxiosError) => {
            logger(Severity.Error, error.message);
            errorToast("Login Error", error.message);
        },
    });

    return (
        <div className="flex h-screen w-screen flex-col items-center justify-center">
            <img src="/images/logo.png" alt="AVL Logo" className="w-96 dark:hidden" />
            <img src="/images/logo-white.png" alt="AVL Logo" className="hidden w-96 dark:block" />
            <div className="card w-full sm:max-w-xl">
                <form className="grid grid-cols-1 gap-4" onSubmit={handleSubmit}>
                    <TextBox
                        id="login-email"
                        label="Email"
                        type="email"
                        autoComplete="email"
                        value={state.email}
                        changeHandler={(value: string) => setState({ ...state, email: value })}
                        required
                    />
                    <TextBox
                        id="login-password"
                        label="Password"
                        type="password"
                        autoComplete="password"
                        value={state.password}
                        changeHandler={(value: string) => setState({ ...state, password: value })}
                        required
                    />
                    <div className="flex w-full justify-end">
                        <Link tabIndex={-1} to={`/${RouteValues.FORGOT_PASSWORD}`}>
                            Forgot password?
                        </Link>
                    </div>
                    <Button isLoading={loginMutation.isPending} className="w-full" type="submit">
                        Sign In
                    </Button>
                </form>
            </div>
        </div>
    );
}

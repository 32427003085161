import { useContext, useMemo, useState } from "react";
import { VerificationsApi } from "../../api/verificationsApi";
import { UserContext } from "../../contexts/userContext";
import {
    QueryKeys,
    Severity,
    formatDateTimeOf,
    formatPhone,
    getTimezoneAbbreviation,
    insertIf,
    logger,
    usePropReducer,
    useTransitionState,
} from "../../helpers";
import { useMapVerbiage } from "../../hooks/verbiageHooks";
import { type IFilter, SortType, type VerificationDto, type VerificationFilter } from "../../models";
import { UserPermissionFlags } from "../../models/permissions";
import { Column } from "../basic";
import { Export } from "../gridFilters/filters/export";
import { FilterGroups } from "../gridFilters/types";
import { GridPage } from "../gridPage";
import { VerificationGridDetail } from "./details";

export default function VerificationsGrid() {
    const { user, hasPermission, isVoterFi } = useContext(UserContext);
    const mapVerbiage = useMapVerbiage();

    const searchOptions = useMemo(
        () => [
            { id: "Rep", label: mapVerbiage("Rep") }, //possibly searches on rep name as well (get rep Id from Reps table then search)
            { id: "Phone", label: "Phone" },
            { id: "FirstName", label: "First Name" },
            { id: "LastName", label: "Last Name" },
            ...insertIf(isVoterFi, { id: "VoterId", label: "Voter Id" }),
            ...insertIf(isVoterFi, { id: "FormNumber", label: "Form Number" }),
        ],
        [mapVerbiage, isVoterFi],
    );

    const [filterData, dispatchFilterData] = usePropReducer({} as VerificationFilter);
    const fullFilterData = useMemo(() => ({ ...filterData, searchOptions }), [filterData, searchOptions]);
    const [itemsPerPage, setItemsPerPage] = useTransitionState(10);
    const [currentPage, setCurrentPage] = useTransitionState(1);
    const [sortFilter, setSortFilter] = useState<IFilter | undefined>(undefined);
    const [pinnedIds, setPinnedIds] = useState<string[]>([]);

    const columns = useMemo(() => {
        const columns = [
            new Column("AVL ID", "id"),
            user?.clientId ? undefined : new Column("Organization", "clientName", SortType.none, false),
            user?.channelId ? undefined : new Column("Channel", "channelName", SortType.none, false),
            user?.stateId ? undefined : new Column("State", "stateName", SortType.none, false),
            new Column(mapVerbiage("Campaign"), "brandName"),
            new Column(mapVerbiage("SaleId"), "saleId"),
            new Column(`${mapVerbiage("DateOfSale")} (${getTimezoneAbbreviation()})`, "dateOfSaleUTC").withFormat(
                formatDateTimeOf,
            ),
            new Column(mapVerbiage("Rep"), "repId"),
            new Column(mapVerbiage("Customer")).withFormat((v: VerificationDto) => `${v.firstName} ${v.lastName}`),
            new Column("Phone", "outboundPhone").withFormat(formatPhone),
            new Column("Status").withFormat((v: VerificationDto) =>
                v.dispositionName ? (v.success ? "Verified" : "Not Verified") : v.statusText,
            ),
            new Column("Disposition", "dispositionName", SortType.none, false),
        ] as Column<VerificationDto>[];

        return columns.filter(Boolean);
    }, [user?.channelId, user?.clientId, user?.stateId, mapVerbiage]);

    async function getData(signal: AbortSignal) {
        try {
            return await VerificationsApi.getVerifications(signal, itemsPerPage, currentPage, {
                ...filterData,
                ...sortFilter,
            });
        } catch (ex) {
            logger(Severity.Error, ex);
        }
    }

    async function exportData() {
        return await VerificationsApi.exportVerifications({
            ...filterData,
            ...sortFilter,
            ids: pinnedIds.map((id) => parseInt(id)),
        });
    }

    async function exportFiles() {
        return await VerificationsApi.downloadAllFilesZip({
            ...filterData,
            ...sortFilter,
            ids: pinnedIds.map((id) => parseInt(id)),
        });
    }

    return (
        <GridPage<VerificationFilter, VerificationDto>
            title="Verifications"
            actionButton={
                hasPermission(UserPermissionFlags.BulkExport) ? (
                    <div className="flex gap-1">
                        <Export
                            filters={filterData}
                            exportData={exportData}
                            exportFileName="verifications"
                            exportFiles={exportFiles}
                        />
                    </div>
                ) : null
            }
            filters={fullFilterData}
            updateFilter={dispatchFilterData}
            visibleFilters={[
                FilterGroups.Verification,
                FilterGroups.Segment,
                FilterGroups.Language,
                FilterGroups.Search,
                FilterGroups.SearchItems,
                FilterGroups.Date,
                FilterGroups.Disposition,
            ]}
            queryKey={QueryKeys.VERIFICATIONS}
            getData={getData}
            columns={columns}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={(itemsPerPage: number) => setItemsPerPage(itemsPerPage)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            sortFilter={sortFilter}
            setSortFilter={setSortFilter}
            getRowClassNames={(row) => rowClassByStatusAndDisposition(row.original)}
            expandable
            pinnable
            getRowId={(row) => row.id.toString()}
            getRowCanExpand={() => true}
            renderSubComponent={(row) => <VerificationGridDetail row={row.row} />}
            pinnedIds={pinnedIds}
            setPinnedIds={setPinnedIds}
        />
    );
}

function rowClassByStatusAndDisposition(verification: VerificationDto) {
    if (verification.statusText.indexOf("Error") > -1) return "bg-pink-200 dark:bg-pink-800"; //Errored

    if (verification.endDateTime)
        if (verification.success)
            return "bg-emerald-200 dark:bg-emerald-800"; //Good Sale
        else return "bg-rose-200 dark:bg-rose-800"; //No Sale

    if (verification.startDateTime)
        //Connected/Opened
        return "bg-orange-200 dark:bg-orange-600"; //In-progress

    if (verification.initiateDateTime)
        //Dialed/Invite sent
        return "bg-orange-100 dark:bg-orange-400";

    return "bg-stone-100 dark:bg-stone-600"; //Delayed initiating/awaiting inbound call
}

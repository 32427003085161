import { type FC, useContext } from "react";
import { UserContext } from "../../contexts/userContext";

export const Logo: FC = () => {
    const { isVoterFi } = useContext(UserContext);
    return isVoterFi ? (
        <>
            <img src="/images/logo-white-voterfi.png" alt="VoterFi" className="hidden w-60 dark:block" />
            <img src="/images/logo-voterfi.png" alt="VoterFi" className="w-60 dark:hidden" />
        </>
    )
    : (
        <>
            <img src="/images/logo-white.png" alt="Advanced Verification" className="hidden w-60 dark:block" />
            <img src="/images/logo.png" alt="Advanced Verification" className="w-60 dark:hidden" />
        </>
    );
};
